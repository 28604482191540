import { ContactInterface } from 'src/interfaces/contact'
import HTTPRequest from '../index'

const apiPathSubmitContact = '/api/v2/guests/contact'

class Contact extends HTTPRequest {
  submit(value: ContactInterface) {
    return this.post<ContactInterface>(`${apiPathSubmitContact}`, { ...value })
  }
}

export default new Contact()
