import clinic_3 from 'src/assets/images/landing/clinic_3.jpg'
import doctor_1 from 'src/assets/images/landing/doctor_1.png'
import clinicLogoHj from 'src/assets/images/landing/clinic_logo_hj.svg'

export const clinicsData = {
  lpClinics: [{ uuid: 'hj', logo: clinicLogoHj }],
  clinics: [
    {
      name: 'Chloe Cheongdam Clinic 赤坂 / クロエ チョンダム クリニック​ アカサカ',
      address: '東京都港区赤坂3-9-3赤坂見附駅前ビル2F/3F',
      access:
        '1. 赤坂見附駅（東京メトロ銀座線・丸ノ内線）から、徒歩約2分 \n 2. 赤坂駅（東京メトロ千代田線）から、徒歩約2分 \n 3. 溜池山王駅（東京メトロ銀座線・南北線）から、徒歩約6分',
      business_hours: '10:00~19:00',
      close_time: '',
      note: '',
      image_url: clinic_3,
      id: 3,
    },
  ],
  doctors: [
    {
      clinic_name: 'Chloe Cheongdam Clinic 管理医師 (院長)',
      name: 'あいはらゆみ',
      name_kana: '相原優美',
      career_introduction:
        '国家公務員共済組合連合会 平塚共済病院 \n 東京中央美容外科 \n 表参道メディカルクリニック 横浜院院長 \n AIビューティークリニック \n クロエチョンダムクリニック院長',
      image_url: doctor_1,
      id: 1,
    },
  ],
}
