import { ContactInterface } from '../interfaces/contact'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { UseFormReturn } from 'react-hook-form/dist/types/form'

import contactRequest from 'src/api/requests/contact'
import uiActions from 'src/store/ui/notification'

export interface PostContact {
  params: ContactInterface
  form: UseFormReturn<ContactInterface, any>
}
export const submitContact = createAsyncThunk<any, PostContact>(
  'reset_password',
  async ({ params }, { dispatch }) => {
    try {
      await contactRequest.submit(params)
    } catch (err: any) {
      dispatch(
        uiActions.onNotification({
          type: 'error',
          message: err.response.data.message,
        }),
      )
    }
  },
)
