import { AxiosRequestConfig } from 'axios'

export const PAGINATE = {
  DEFAULT_PAGE: 1,
  PER_PAGE: 10,
}

export const HTTP_CODE = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
}

export const DATE_TIME = 'YYYY-MM-DD'
export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_TIME_FORMAT = 'YYYY-MM-DD'
export const DATE_TIME_HOUR = 'YYYY-MM-DD HH:mm:ss'
export const HOUR_MIN = 'HH:mm'
export const HOUR_MIN_SECOND = 'HH:mm:ss'

export const JP_DATE_FORMAT = 'YYYY年MM月DD日'
export const JP_DATE_TIME_FORMAT = 'YYYY年MM月DD日 HH:mm'

export const config: AxiosRequestConfig = {
  baseURL: `${process.env.REACT_APP_BASE_API_URL}` || 'http://localhost:3000',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
}
