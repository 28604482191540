import React from 'react'
import styled from 'styled-components'

const ExampleComponent: React.FC = () => {
  return <Wrapper id="example">処方されるお薬例</Wrapper>
}

const Wrapper = styled.div``

export default ExampleComponent
