import notFoundErrorPage, { PATH as NOT_FOUND } from './404'
import forbiddenErrorPage, { PATH as FORBIDDEN } from './403'
import HTTPInternalErrorPage, { PATH as HTTP_INTERNAL } from './500'

export default {
  notFoundErrorPage,
  forbiddenErrorPage,
  HTTPInternalErrorPage,
}
export const PATH = {
  NOT_FOUND,
  FORBIDDEN,
  HTTP_INTERNAL,
}
