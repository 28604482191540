export class LocalStorage {
  static get accessToken() {
    return localStorage.getItem('ACCESS_TOKEN') || null
  }

  static clearLocalStorage() {
    localStorage.clear()
  }
  // static get refreshToken() {
  //   return localStorage.getItem(STORAGE_KEY.REFRESH_TOKEN) || null
  // }
  // static get authInfo() {
  //   return JSON.parse(localStorage.getItem(STORAGE_KEY.AUTH_ME_INFO) || 'null')
  // }
  // static setToken(payload: any) {
  //   localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, payload)
  //   localStorage.setItem(STORAGE_KEY.REFRESH_TOKEN, payload)
  // }
}

export default LocalStorage
