import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  loading: boolean
}

const initialState: State = {
  loading: false,
}

const { actions, reducer } = createSlice({
  // TODO: remember change the name of store
  name: 'app',
  initialState,
  reducers: {
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
})

export { reducer }
export default actions
