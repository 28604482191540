import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Progress } from 'antd'

const LoadingProgress: React.FC = () => {
  const [loadingPercent, setLoading] = useState<number>(0)

  useEffect(() => {
    setInterval(() => {
      setLoading((val) => (val < 100 ? val + 1 : val))
    }, 5)
  }, [])

  useEffect(() => {
    if (loadingPercent === 100 && location.hash) {
      setTimeout(() => {
        const element = document.querySelector(location.hash)
        element?.scrollIntoView({ behavior: 'smooth' })
      }, 200)
    }
  }, [loadingPercent])

  const loadingSuccess = () => {
    return loadingPercent === 100 ? 'progress-loading--hide' : ''
  }

  return (
    <Wrapper>
      <div className={`progress-loading ${loadingSuccess()}`}>
        <Progress
          percent={loadingPercent}
          status="active"
          trailColor="#000"
          size="small"
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .progress-loading {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1002;
    transition: 0.9s ease-in-out;

    &--hide {
      left: 100%;
    }

    .ant-progress {
      overflow: hidden;
      display: flex;
      padding: 0;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;

      &-bg {
        background-color: var(--color-gurkha-dark);
      }

      &-text {
        color: var(--color-gurkha-dark);
        font-weight: bold;
        margin: 0;
        font-size: 16px;
      }
    }
  }
`

export default LoadingProgress
