import React from 'react'

import { PATH } from 'src/pages/error'
import loadable from 'src/utils/Loadable'
import { IRouter } from 'src/interfaces/route'
import GuestErrorLayout from 'src/routes/components/GuestErrorLayout'

const ErrorRoute: IRouter[] = [
  {
    element: <GuestErrorLayout />,
    children: [
      {
        path: PATH.NOT_FOUND,
        element: loadable(() => import('src/pages/error/404')),
      },
      {
        path: '*',
        element: loadable(() => import('src/pages/error/404')),
      },
      {
        path: PATH.HTTP_INTERNAL,
        element: loadable(() => import('src/pages/error/500')),
      },
      {
        path: PATH.FORBIDDEN,
        element: loadable(() => import('src/pages/error/403')),
      },
    ],
  },
]

export default ErrorRoute
