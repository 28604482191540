import React from 'react'
import styled from 'styled-components'

import aboutBg from 'src/assets/images/about-bg.png'
import aboutBgWhite from 'src/assets/images/about-bg-white.png'
import aboutDetail1 from 'src/assets/images/about-1.svg'
import aboutDetail2 from 'src/assets/images/about-2.svg'
import aboutDetail3 from 'src/assets/images/about-3.svg'

const AboutComponent: React.FC = () => {
  const aboutDetail = [
    {
      title: 'オンライン診療',
      content:
        'オンラインで医師に \nスキンケアの相談した医薬品の \n処方を受けることができます',
      img_url: aboutDetail1,
    },
    {
      title: '医療用医薬品',
      content:
        'あなたにぴったりの \n医療用医薬品内服薬・外服薬を \nお届けします',
      img_url: aboutDetail2,
    },
    {
      title: '信頼できる医師が診察',
      content:
        'サポート対応時間:10:00~19:00(土日祝休み) \nビデオ診察対応時間:10:30~18:00(金土休診) \n※予約状況によっては対応できない場合がございます',
      img_url: aboutDetail3,
    },
  ]
  return (
    <Wrapper id="about-bioport">
      <div className="about-info pr-30 pl-30 pt-25">
        <div data-aos="fade-down">
          <h4 className="lp-page__header ">About BIOPORT+</h4>
          <p className="text-center about-info__sub">BIOPORT+とは</p>
        </div>
        <div className="mt-40" data-aos="fade-up" data-aos-delay="500">
          <p className="mb-30">溜め込むことで生まれる体の不調。</p>
          <p className="mb-30">
            カラダにとっていい状態とはなにか。
            <br /> どうすれば維持できるのか。
          </p>
          <p>
            頑張ることの多い日常で、 <br />
            いい状態を維持するために頑張るのではなく、 <br />
            頑張りすぎないために <br />
            続けられることを大切にしたい。
          </p>
        </div>
      </div>
      <div
        className="about-detail mt-40 mb-50 px-3"
        data-aos="fade-up"
        data-aos-delay="500"
      >
        {aboutDetail.length > 0 &&
          aboutDetail.map((item, index) => {
            return (
              <div
                className="about-detail__item d-flex align-items-center mb-20"
                key={index}
              >
                <div className="about-detail__left mr-30">
                  <h4 className="font-weight-bold mb-15 fs-18">{item.title}</h4>
                  <p className="fs-13">{item.content}</p>
                </div>
                <img src={item.img_url} alt="" />
              </div>
            )
          })}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: -15px;

  .about {
    &-info {
      background-image: url(${aboutBgWhite}), url(${aboutBg});
      background-position: left bottom, left top;
      background-repeat: no-repeat, no-repeat;
      background-size: 100% 80%, 100%;

      &__sub {
        letter-spacing: 0.1em;
      }

      p {
        line-height: 185%;
      }
    }

    &-detail {
      &__left {
      }

      p {
        white-space: pre-wrap;
      }
    }
  }
`

export default AboutComponent
