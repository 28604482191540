import React from 'react'
import styled from 'styled-components'

const BookingEndComponent: React.FC = () => {
  return <Wrapper>オンライン診療のご予約</Wrapper>
}

const Wrapper = styled.div``

export default BookingEndComponent
