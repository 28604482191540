import { Deserializable } from 'src/interfaces/deserializable'
import { MedicineInterface } from 'src/interfaces/medicine'

export class Medicine implements Deserializable<Medicine>, MedicineInterface {
  id: number = 0
  name: string = ''
  image_url: string = ''

  constructor() {}

  deserialize(input: Partial<MedicineInterface>): Medicine {
    Object.assign(this, input)

    return this
  }
}
