export const MEDICAL_EXAMINATION = {
  value: 'medical_examination',
  name: '診察について',
}
export const RESERVATION = {
  value: 'reservation',
  name: '予約について',
}
export const PAYMENT = {
  value: 'payment',
  name: 'お支払いについて',
}
export const MEDICAL = {
  value: 'medicine',
  name: 'お薬について',
}
export const DELIVERY = {
  value: 'delivery',
  name: '配送について',
}
export const CANCEL_DELIVERY = {
  value: 'cancel_delivery',
  name: '定期配送のキャンセルについて',
}
export const OTHER = {
  value: 'other',
  name: 'その他',
}

export const contactTypes = [
  {
    id: 0,
    value: '',
    label: '選択してください',
  },
  {
    id: 1,
    value: MEDICAL_EXAMINATION.value,
    label: MEDICAL_EXAMINATION.name,
  },
  {
    id: 2,
    value: RESERVATION.value,
    label: RESERVATION.name,
  },
  {
    id: 3,
    value: PAYMENT.value,
    label: PAYMENT.name,
  },
  {
    id: 4,
    value: MEDICAL.value,
    label: MEDICAL.name,
  },
  {
    id: 5,
    value: DELIVERY.value,
    label: DELIVERY.name,
  },
  {
    id: 6,
    value: CANCEL_DELIVERY.value,
    label: CANCEL_DELIVERY.name,
  },
  {
    id: 7,
    value: OTHER.value,
    label: OTHER.name,
  },
]
