import React from 'react'
import styled from 'styled-components'
import { Result } from 'antd'
import ButtonCustom from 'src/components/form/Button'
import { useNavigate } from 'react-router-dom'

const NotFoundErrorContainer: React.FC = () => {
  const navigate = useNavigate()
  return (
    <Wrapper>
      {/* <LPHeader /> */}
      <Result
        status="404"
        title="404"
        subTitle="このページが存在していません。"
        extra={
          <ButtonCustom
            className={'mt-5 btn-black'}
            loading={false}
            onClick={() => navigate('/')}
            name={'ホームへ戻る'}
          />
        }
      />
    </Wrapper>
  )
}

const Wrapper = styled.div``

export default NotFoundErrorContainer
